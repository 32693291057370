.SplashScreen{
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    min-height: 100vh;
    background-color: white;
    overflow: hidden;
    min-width: 100vw;
      
      // .deconstructed {
      //   position: relative;
      //   margin: auto;
      //   // height: 100vh;
      //   color: transparent;
      //   font-family: 'Cambay', sans-serif;
      //   font-size: 10vw;
      //   font-weight: 700;
      //   letter-spacing: -0.02em;
      //   line-height: 1.03em;
      //   display: flex;
      //   width: 100vw;
      //   overflow: hidden;
      //   align-items: center;
      //   justify-content: center;

        
      // }
      
      // .deconstructed > div {
      //   position: absolute;
      //   color: var(--blue2);
      //   display: flex;
      //   align-items: center;
      //   pointer-events: none;
      //   color: white;
      //   opacity: 0.4;
      //   margin-left: 25px;
      //   text-shadow: -3px 0 var(--blue2), 0 3px var(--blue2), 3px 0 var(--blue2), 0 -3px var(--blue2);
      // }
      
      // .deconstructed > div:nth-child(1) {
      //   -webkit-mask-image: linear-gradient(black 25%, transparent 25%);
      //   mask-image: linear-gradient(black 25%, transparent 25%);
      //   animation: deconstructed1 5s infinite;
      // }
      
      // .deconstructed > div:nth-child(2) {
      //   -webkit-mask-image: linear-gradient(transparent 25%, black 25%, black 50%, transparent 50%);
      //   mask-image: linear-gradient(transparent 25%, black 25%, black 50%, transparent 50%);
      //   animation: deconstructed2 5s infinite;
      // }
      
      // .deconstructed > div:nth-child(3) {
      //    -webkit-mask-image: linear-gradient(transparent 50%, black 50%, black 75%, transparent 75%);
      //   mask-image: linear-gradient(transparent 50%, black 50%, black 75%, transparent 75%);
      //   animation: deconstructed3 5s infinite;
      // }
      
      // .deconstructed > div:nth-child(4) {
      //    -webkit-mask-image: linear-gradient(transparent 75%, black 75%);
      //   mask-image: linear-gradient(transparent 75%, black 75%);
      //   animation: deconstructed4 5s infinite;
      // }
      
      // @keyframes deconstructed1 {
      //   0% {
      //     transform: translateX(100%);
      //   }
      //   26% {
      //     transform: translateX(0%);
      //   }
      //   83% {
      //     transform: translateX(-0.1%);
      //   }
      //   100% {
      //     transform: translateX(-120%);
      //   }
      // }
      
      // @keyframes deconstructed2 {
      //   0% {
      //     transform: translateX(100%);
      //   }
      //   24% {
      //     transform: translateX(0.5%);
      //   }
      //   82% {
      //     transform: translateX(-0.2%);
      //   }
      //   100% {
      //     transform: translateX(-125%);
      //   }
      // }
      
      // @keyframes deconstructed3 {
      //   0% {
      //     transform: translateX(100%);
      //   }
      //   22% {
      //     transform: translateX(0%);
      //   }
      //   81% {
      //     transform: translateX(0%);
      //   }
      //   100% {
      //     transform: translateX(-130%);
      //   }
      // }
      
      // @keyframes deconstructed4 {
      //   0% {
      //     transform: translateX(100%);
      //   }
      //   20% {
      //     transform: translateX(0%);
      //   }
      //   80% {
      //     transform: translateX(0%);
      //   }
      //   100% {
      //     transform: translateX(-135%);
      //   }
      // }

      .image-holder{

        .img1{
          display: grid;
          

          .i1{
          animation-name: image1;
          max-width: 330px;
          animation-duration: 2s;
          animation-fill-mode: forwards;
          }
          
          @keyframes image1 {
            from{
              transform: scale(0.9);
              opacity: 0;
            }
            50%{
              transform: scale(1);
              opacity: 1;
            }
            to{
              opacity: 0;
              transform: scale(0.9);
            }

          }
          img{
          }

          .i2{
            visibility: hidden;
            animation-name: i12;
            animation-delay: 2s;
            animation-duration: 1s;
            // animation-fill-mode: forwards;
          }
          
          @keyframes i12 {
            from{
              visibility: visible;
              // transform: translateX(40px);
              transform: scale(0);
            }
            to{
              visibility: visible;
              // transform: translateX(25px);
              transform: scale(10);
            }
          }
        }
        .img2{
          display: grid;
          animation-name: image2;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          align-items: center;

          .i1{
            max-width: 300px;
          }


          @keyframes image2 {
            from{
              display: grid;
              opacity: 0;
              transform: scale(0.9);
            }
            to{
              opacity: 1;
              transform: scale(1);
            }

          }
          
          .i2{
            visibility: hidden;
            animation-name: i2;
            animation-delay: 1s;
            animation-duration: 1s;
            animation-fill-mode: forwards;
          }
          
          @keyframes i2 {
            from{
              transform: translateX(40px);
              transform: scale(0);
              visibility: visible;
            }
            to{
              visibility: visible;
              transform: translateX(25px);
              transform: scale(10);
            }
          }
        }
        .img2 > * {
          grid-column-start: 1;
          grid-row-start: 1;
        }
        .img1 > * {
          grid-column-start: 1;
          grid-row-start: 1;
        }
      }

}