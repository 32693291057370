.Router{
    display: flex;
    width: 100%;
    max-height: 100vh;

    .sidebarWrapper{
        width: 25%;
        position: sticky;
    }

    .pageWrapper{
        width: 100%;
        overflow: auto;
    }
}