.table-page{
    width: 100vw;
    z-index: 100;
    background: white;
    padding: 5%;
    max-width: 100vw;
    overflow-x: hidden;

    .btn-div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .back-btn{
            margin-right: auto;
            color: var(--blue2);
            font-size: 30px;
            
    }
    button{
        cursor: pointer;
            background-color: transparent;
            border: none;
    }
    
    }
    

    .table{
        width: 100%;
        margin: auto;
        // max-width: 80%;
        overflow-x:scroll;
        // -ms-overflow-style: none;  
        scrollbar-width: 2px; 
        border: 1px solid #E4E4E4;
        border-radius: 10px;
        
        &::-webkit-scrollbar {
            width: 2px;
            height: 8px;
        }

        
          
    }
 }