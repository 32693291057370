.feature-soon{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    h6{
        margin-top: 100px;
        font-size: 2.25em;
        color: var(--blue2);
    }
}