.user-list{
    padding:0px  50px;
    // padding-top: 20px;

    .td-input-parent{
        padding: 6px;
    }

    tbody{
        .color{
            input{
                &:focus{
                    background-color: white;
                    box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6);
                }
            }
        }
        .non-color{
            input{
                &:focus{
                    background-color: var(--blue1);
                    box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6);
                }
            }
        }
    }
    
    input{
        border-radius: 8px;
        padding: 6px;
        width: 80%; 
    }
    
    .invoice-input{
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-bottom: 10px;
        input{
            outline: none;
            box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6);
            border-radius: 10px;
            color: var(--blue2);
            background: var(--blue1);
            padding: 12px 10px;
            border: none;
            width: 25%;  
          
        &::placeholder{
            color: var(--blue2);
          
          }
        }

        button {
            padding: 5px;
            background: none;
            border: none;
            cursor: pointer;
            color: #0762A3;

            i {
                color: #0762A3;
                font-size: 20px;
            }
        }

        .button-div-add {
            padding: 10px;
            background: #D6ECFD;
            color: #0762A3;
            border-radius: 6px;
        }
    }

    // number field increamentor


        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }

    .button_div{
        display: flex;
        justify-content: space-between;
        // margin-bottom: 10px;
        // margin: 0px !important;
        padding-top: 40px;

        

        button{
            background: none;
            border: none;
            cursor: pointer;
            color: #0762A3;
            margin-bottom: 10px;

            i{
                color: #0762A3;
                font-size: 20px;
            }
        }

        .button-div-add{
            padding: 19px;
            background: #D6ECFD;
            color: #0762A3;
            border-radius: 16px;
        }
    }
    
}