.upload{
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    .bg{
        background-color: white;
        height: 100%;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .highlighted-txt{
        p{
            font-size: 1.25em;
            color: var(--blue2);
            font-weight: 600;
            margin-top: 30px;
        }
    }
    
    .upload-img{
        display: flex;
        align-items: center;
        justify-content: center;
        position:relative;

        img{
            width: 65%;
        }
        
        #input1{
            
            width: 100%;
            height: 100%;
            background-color: #e2e2e2;
            border: none;
            outline: none;
            /* padding: 12px 20px; */
            border-radius: 4px;
            opacity: 0;
            position: absolute;
            
        }
    }
    
    .uploader{
        height: 25vw;
        border-radius: 25px;
        width: 55%;
        background-color: #D6ECFD;
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content:center ;
        align-items: center;
        align-content: center;
        // border: 5px dashed var(--gray1);
        outline:2px dashed rgb(184, 167, 167);
outline-offset:-40px;

        &.active{
            // border: 0px !important;
            outline: none;
        }

        .upload-drop{
            background-color: white;
            border-radius: 50%;
            height: 10vw;
            width: 10vw;
            display: flex;
            justify-content:center ;
            align-items: center;

        }

        .upload-inner{
            background-color: white;
            border: 5px solid #ebe5e5;
            border-radius: 50%;
            height: 7vw;
            width: 7vw;
            display: flex;
            flex-direction: column;
            justify-content:center ;
            align-items: center;
        }

        .upload-loader{
            height: 20px;
            background-color: #D6ECFD;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50px;
            width: 80%;
            // margin: auto;
            margin-top: 20px;



            .progress-bar{
                transition: all ease-in 0.5s;
                height: 100%;
                background: #0762A3;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 50px;
            }
        }
    }

    .uploaded{
        width: 100%;
    }


}

.calender_input::-webkit-calendar-picker-indicator {
    // filter: invert(1) !important;
    // display: none;
    // opacity: 0;
}

input{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}