.login {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    
    .content {
        width: 35%;
        background-color: var(--blue1);
        padding: 40px 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2{
            color: var(--blue2);
        }

        .form {
            width: 50%;
            margin: 20px 0;
        }

        .input_section {
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;

            .password{
                position: absolute;
                right: 10px;
                cursor: pointer;
                
                i{
                    width: 25px;
                    color: var(--blue2);
                }
            }
        }

        .forgot{
            width: 50%;
            color: var(--blue2);
            font-weight: 500;
            font-size: 0.75em;
        }

        .signin{
            padding: 15px 20px;
            margin: 10px;
            background-color: white;
            border-radius: 10px;
            border: 0;
            color: var(--blue2);
            font-weight: 500;
            font-size: 1.125em;
            cursor: pointer;
        }

        p{
            color: var(--blue2);
            font-weight: 500;
        }

        .s-google{
            display: flex;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            padding: 10px 15px;
            margin-top: 20px;
            cursor: pointer;
            img{
                width: 25px;
                padding-right: 10px;
                border-right: solid 2px var(--blue2);
            }

            p{
                padding-left: 10px;
                color: var(--blue2);
            }
        }
    }
}

.mt-1{
    margin-top: 10px;
}

.navigate{
    a{
        color: var(--blue2) !important; 
        text-decoration: none;
    }

}
.error{
    p{
        color: red !important;
    }
}
