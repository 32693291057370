.loader {
  height: 100vh;
  top: 0;
  width: 100vw;
  position: fixed;
  background: white;
  opacity: 0.5;
  z-index: 999;
  display: flex;
  z-index: 99999;
  align-items: center;
  position: fixed;
  width: 100vw;
  left: 0;
  align-items: center;
  justify-content: center;

  .circle{
    // width: 50%;
    display: flex;

    video{
      width: 400px;
    }
    // padding: 50px;
    // border-radius: 50%;
    // background-color: white;
    // border: 2px solid var(--blue2);
  }

  span {
    display: block;
    margin: 0 auto;
    border-color: var(--green);
    left: calc(50% - 75px);
    // position: fixed;
  }

  .loaderimg {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }

  @-moz-keyframes spin {
    0%   {-moz-transform: rotate(0deg);}
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
    0%   {-webkit-transform: rotate(0deg);}
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    0%   {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}