.password_reset{

    .login{
        flex-direction: column;
    }

    .modal-close-icon{
        position: relative;
        margin-bottom: -15px;
        z-index: 2000;
    }
    .content{
        box-shadow: -16px -16px 40px rgba(253, 255, 255, 0.8), 16px 16px 40px rgba(187, 195, 206, 0.6);
        
    }
    .input_section{
        input {
                box-shadow: "inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6)";
            
        }
    }

    button{
        margin: 0;
        margin-top: -15px;
        box-shadow: 4px 4px 12px rgba(187, 195, 206, 0.6), inset -4px -4px 12px rgba(253, 255, 255, 0.8);
    }
}