.sidebar{
    display: flex;
    flex-direction: column;
    // width: 100%;
    margin-right: auto;
    height: 100vh;
    // position: fixed;
    background-color: var(--blue2);
    padding: 0 30px;

    .logo{
        padding:  20px 0;
        padding-top: 35px;

        .round{
            height: 50px;
        width: 50px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        }
    }

    .menu{
        ul{
            list-style: none;
            li{
                display: flex;
                align-items: center;
                padding: 10px;
                margin: 10px 0;
                cursor: pointer;
                border-radius: 10px;
                i{
                    font-size: 25px;
                    margin-right:10px ;
                    color: white;
                }
                a{
                    display: flex;
                }
                img{
                    margin-right: 20px;
                }

                p{
                    font-size: 1.25em;
                    color: white;
                    font-weight: 600;
                }

                &.active{
                    background-color: white;
                    p{
                        color: var(--blue2);
                    }
                    i{
                        color: #0762A3;
                    }
                }

                &:hover{
                    background-color: white;
                    img{
                        fill: var(--blue2);
                    }
                    p{
                        color: var(--blue2);
                    }
                    i{
                        color: #0762A3;
                    }
                }
            }
        }

    }

    .footer{
        margin-top: auto;
        padding: 10px 0;
        padding-bottom: 20px;

        .logout{
            // background-color: white;
            display: flex;
            padding: 10px;
            align-items: center;
            border-radius: 10px;
            cursor: pointer;
            

            i{
                color: white;
                font-size: 1.75em;
            }
            img{
                margin-right: 20px;
            }

            &:hover{
                background-color: white;

                p{
                    color: var(--blue2)
                }

                i{
                    color: var(--blue2);
                }
            }

            p{
                color: #fff;
                padding-left: 10px;
                font-size: 1.25em;
                font-weight: 600;
            }
        }
    }
}