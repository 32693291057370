.file-show{
    width: 65%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border: solid 2px var(--gray1);
    border-radius: 20px;
    overflow: hidden;

    
    

    .header{
        display: flex;
        padding: 10px;
        background-color: var(--blue2);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        align-items: center;

        .controls-c{
            display: flex;
            align-items: flex-end;
            margin-left: auto;
        }

        .box-c{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0 10px;
                justify-content: center;
                cursor: pointer;
                .box{
                    background-color: white;
                    border: solid 2px var(--gray1);
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    width: 30px;
                    margin-bottom: 9px;
                    box-shadow: inset -4px -4px 4px rgba(0, 0, 0, 0.1), inset 4px 4px 4px rgba(0, 0, 0, 0.25);
                    img{
                        width: 17px;
                        // position: absolute;
                    }
                    i{
                        font-size: 16px;
                        color: var(--blue2);
                        font-weight: bolder;
                    }

                    
                }
                p{
                    color: white;
                    margin-top: 2px;
                }
            }

        .controls{
            display: flex;
            
            

        }

        .bi-arrow-left-short{
            color: white;
            font-size: 25px;
            margin-right: 10px;
            cursor: pointer;
        }

        h6{
            font-size: 1.25em;
            color: white;
        }

        .input-group{
            margin-left: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            // checkbox color
            


            #select-all{
                width: 20px;
                height: 20px;
            }

            label{
                margin-left: 10px;
                color: white;
            }
        }
    }

    .body{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-height: 300px;
        overflow-y: auto;

        .file-div{

            border-bottom: solid 2px var(--gray1);
            padding: 10px;

            .error-div{
                display: flex;
                align-items: center;
                p{
                    color: red !important;
                    font-size: 0.875em;
                    padding-left: 10px;
                }
                i{
                    margin-right: 5px;
                }
            }

            &:hover{
                .icons{
                    visibility: visible;
                    max-height: 20px;
                }
            }
        }

        .details{
            display: flex;
            padding: 10px;
            
            border-radius: 10px;
            cursor: pointer;
            p{
                font-size: 1.125em;
                display: flex;
                align-items: center;
                color: var(--blue2);
                font-weight: 600;
            }
            .icons{
                margin-left: auto;
                display: flex;
                visibility: hidden;
                .icons-holder{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    font-size: 10px;
                }

                img{
                    width: 18px;
                    margin-left: 35px;
                }
            }
        }

        .selected{
            background-color: var(--blue1);
        }
    }

    .deleteWarn{
        background: #E4E4E4 !important;
        display: flex;
        justify-content: space-between;

        i{
            color: var(--blue2) !important;
        }

        .warning-header{
            display: flex;
            align-items: center;
            color: #EB1515;
        }
    }
}