.input_group {
  border-radius: 10px;
  overflow: hidden;

  margin: 10px 0;
  width: 100%;

  input {
    width: 100%;
    padding: 14px;
    border: 0px;
    outline: none;
    color: var(--blue2);

    &::placeholder {
      color: var(--blue2);
      opacity: 1; /* Firefox */
      font-weight: 500;
      font-size: 1em;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--blue2);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--blue2);
    }
  }
}

.select_group {
  select {
    padding: 17px;
    border: 0px;
    outline: none;
    color: var(--blue2);
    background-color: white;
    border-radius: 10px;
    appearance: none; /* Remove default dropdown arrow */
    -webkit-appearance: none; /* Safari compatibility */
    -moz-appearance: none; /* Firefox compatibility */

    &::placeholder {
      color: var(--blue2);
      opacity: 1; /* Firefox */
      font-weight: 500;
      font-size: 1em;
    }

    &:focus {
      outline: 2px solid var(--blue2);
    }
  }

  /* Add a custom dropdown arrow */
  &::after {
    content: "▼";
    font-size: 0.9em;
    color: var(--blue2);
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
