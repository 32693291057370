.home{


    padding: 0;
    // width: 100%;
    // overflow-x: hidden;


    .switch-btn-holder{
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-bottom: 20px;
        button {
            background: #0762A3;
            border: none;
            cursor: pointer;
            color: white;

            i {
                color: #0762A3;
                font-size: 20px;
            }
        }

        .button-div-add {
            padding: 10px;
            background: #D6ECFD;
            color: #0762A3;
            border-radius: 6px;
        }

        .add-provider {
            box-shadow: -4px -4px 12px rgba(253, 255, 255, 0.8), 4px 4px 12px rgba(187, 195, 206, 0.6);
        }
    }
    .right{
        display: flex;
        flex-direction: column;

        .logoMain{
            padding-top: 35px !important;
            padding: 10px 0;
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
        }
    }
}