.master-list {
    padding: 50px !important;
    padding-top: 0px !important;

    .custom-alert {
        position: absolute;
        left: 30.35%;
        right: 30.35%;
        top: 17.29%;
        bottom: 73.73%;
        height: 92px;
        background: var(--blue2);
        box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.59), -4px -4px 12px rgba(236, 236, 236, 0.09);
        border-radius: 10px;
        z-index: 9999999999999999999;
        color: white;
        text-align: center;
        transition: all 0.2s ease-in;

        .alert-text-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 18px;
        }
    }

    .td-input-parent {
        padding: 6px !important;
    }

    tbody {
        // .color {
        //     input {
        //         &:focus {
        //             background-color: white;
        //             box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6) !important;
        //         }
        //     }
        // }

        // .non-color {
        //     input {
        //         &:focus {
        //             background-color: var(--blue1);
        //             box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6) !important;
        //         }
        //     }
        // }
    }

    input {
        border-radius: 8px !important;
        padding: 6px !important;
        width: 80% !important;
    }

    .invoice-input {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-bottom: 10px;

        input {
            outline: none;
            box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6);
            border-radius: 10px;
            color: var(--blue2);
            background: var(--blue1);
            padding: 12px 10px;
            border: none;
            width: 25%;

            &::placeholder {
                color: var(--blue2);

            }
        }

        button {
            background: none;
            border: none;
            cursor: pointer;
            color: #0762A3;

            i {
                color: #0762A3;
                font-size: 20px;
            }
        }

        .button-div-add {
            padding: 10px;
            background: #D6ECFD;
            color: #0762A3;
            border-radius: 6px;
        }
    }

    .add-provider {
        box-shadow: -4px -4px 12px rgba(253, 255, 255, 0.8), 4px 4px 12px rgba(187, 195, 206, 0.6);
    }

    // number field increamentor


    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .button_div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;



        button {
            background: none;
            border: none;
            cursor: pointer;
            color: #0762A3;

            i {
                color: #0762A3;
                font-size: 20px;
            }
        }

        .button-div-add {
            padding: 10px;
            background: #D6ECFD;
            color: #0762A3;
            border-radius: 6px;
        }
    }

    table.dataTable {
        border-collapse: collapse !important;
        margin-bottom: 15px !important;
    }



    table.dataTable tbody>tr.selected,
    table.dataTable tbody>tr>.selected {
        background-color: rgba(86, 100, 210, 0.2);
    }

    table.dataTable tbody>tr.selected td,
    table.dataTable tbody>tr>.selected td {
        border-color: rgba(86, 100, 210, 0.2);
        color: #5664d2;
    }

    table.dataTable tbody td:focus {
        outline: none !important;
    }

    table.dataTable tbody th.focus,
    table.dataTable tbody td.focus {
        outline: 2px solid #5664d2 !important;
        outline-offset: -1px;
        background-color: rgba(86, 100, 210, 0.15);
    }

    .dataTables_info {
        font-weight: 600;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>th:first-child:before {
        -webkit-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
        background-color: #1cbb8c;
        bottom: auto;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
        background-color: #ff3d60;
    }

    .even {
        background-color: var(--blue1);

        input {

            &:focus {
                background-color: white !important;
                box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6) !important;
            }
        }
    }

    .odd {
        background-color: white !important;

        input {
            &:focus {
                background-color: var(--blue1) !important;
                box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6) !important;
            }
        }
    }

    .dataTables_filter {

        margin-bottom: 10px;

        label {
            display: flex;
            align-items: center;
            gap: 5px;

            input {
                outline: none;
                border-radius: 10px;
                color: var(--blue2);
                background: var(--blue1);
                padding: 10px 12px;
                border: none;
                height: 35px;
                padding: 10px 5px;
                // margin-top: 20px;

                &:focus {
                    outline: none;
                }
            }
        }

    }

    #example_length {

        select {
            outline: none;
            border-radius: 10px;
            color: var(--blue2);
            background: var(--blue1);
            padding: 10px 12px;
            border: none;
            height: 35px;
            padding: 4px 15px;

            &:focus {
                outline: none;
                // background-color: white;
            }

            option {
                padding: 4px;
                height: 15px;
            }
        }


        label {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .sticky {
        position: sticky;
        padding: 15px 0px;
        top: 0;
        display: flex;
        justify-content: center;
        z-index: 999;
        background-color: white;
        width: inherit;
    }

    .dataTables_paginate {
        .current {
            background-color: var(--blue1) !important;
        }

        .paginate_button {
            &:hover {
                background-color: var(--blue1) !important;
            }

            &:target {
                background-color: var(--blue1) !important;
            }
        }
    }
}