.Footer{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B7B4B4;
    font-weight: 600;
    font-size: 16px;
    img{
        position: relative;
        transform: translateX(5px);
    }
}