table {
    width: 100%;
    border-spacing: 0px;
    padding: 0px 0px !important;
    overflow: hidden;
    border-radius: 10px;

    thead {
        background-color: var(--blue1);

        th {
            border: 1px solid var(--gray1);

            p {
                white-space: nowrap;
                padding: 10px;
                text-align: left;
            }
        }
    }

    tbody {

        td{
            padding: 5px;
        }

        input{
            border-radius: 10px;
        }
            .color {
                padding: 5px;
                background-color: var(--blue1);
                input {
                    &:focus {
                        background-color: white;
                        box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6) !important;
                    }
                }
            }
    
            .non-color {
                padding: 5px;
                input {
                    &:focus {
                        background-color: var(--blue1);
                        box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6) !important;
                    }
                }
            }
        
        td {
            border: 1px solid var(--gray1);
            background-color: transparent;
            

            input{
                background-color: transparent;
                border: 0;
                outline: none;
                padding: 10px ;

            }

            p {
                padding: 10px;
            }
        }
    }
}
