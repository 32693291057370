@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
}

:root {
  --gray1: #e4e4e4;
  --blue1: #d6ecfd;
  --blue2: #0762A3;
}


body{
  font-size: 16px;
  font-family: 'Open Sans', sans-serif !important;
}



@media only screen and (max-width: 1280px) {
  body {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 1000px) {
  body {
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 1600px) {
  body {
    font-size: 13px;
  }
}


/* width */
::-webkit-scrollbar {
  width: 7px;
  /* border-radius: 50px; */
  
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0762A3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2c75a9;
}


/* Modal */

.master-modal-header{
  padding: 15px 20px !important;
  font-size: 24px;
  display: flex;
  /* gap: 300px; */
  justify-content: space-between;
  background-color: var(--blue2);
  align-items: baseline;
  color: white;

}

.master-modal-body{
  border-radius: 20px;
  /* display: flex; */
  padding: 60px 30px;
  justify-content: space-between;
  gap: 10px;
  background-color: var(--blue2);
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.59), -4px -4px 12px rgba(236, 236, 236, 0.09);
  font-family: 'Open Sans', sans-serif !important;
  display: flex;
  flex-direction: column;
}

.master-modal-body input{
  outline: none;
  box-shadow: inset -4px -4px 12px rgba(253, 255, 255, 0.8), inset 4px 4px 12px rgba(187, 195, 206, 0.6);
  border-radius: 10px;
  color: var(--blue2);
  /* background: var(--blue1); */
  padding: 7px 12px;
  border: none;
  height: 35px;
  background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #FFFFFF;
background-blend-mode: soft-light, normal;
/* n-stroke */

border: 1px solid rgba(255, 255, 255, 0.4);
/* n-5-5-10-in */

box-shadow: inset -5px -5px 8px #F9FCFF, inset 5px 5px 7px rgba(174, 174, 192, 0.75);
border-radius: 10px;
padding-left: 20px;
}
.master-modal-body input::placeholder{
  color: var(--blue2);

}


.master-modal-body button{
  padding: 12px 15px;
  background: white;
  border: none;
  color: var(--blue2);
  cursor: pointer;
  border-radius: 10px;
  box-shadow:5px 6px 12px rgb(0 0 0 / 36%), 0px -4px 12px rgb(236 236 236 / 9%);

}

.master-modal-body input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.master-modal-body input[type=number] {
  -moz-appearance: textfield;
}

.ReactModal__Content{
  overflow: visible;
}

.modal-close-icon{
  position: absolute;
  transform: translateY(-2px);
  background-color: white;
  color: var(--blue2);
  aspect-ratio: 1/1;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  background: linear-gradient(138.32deg, rgba(0, 0, 0, 0.5) 8.26%, rgba(255, 255, 255, 0.5) 91.02%), #FFFFFF;
  background-blend-mode: soft-light, normal;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 1px -1px 4px rgba(27, 27, 27, 0.25), -3px 2px 4px rgba(21, 21, 21, 0.25);
  border-radius: 18px;
  cursor: pointer;
}

.modal-close-icon i{
  font-size: 22px;
  font-weight: 900px;
}

.ReactModal__Overlay{
  background-color: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(8px);
  z-index: 999;
}

.input-check{
  outline: none;
  box-shadow: none !important;
  margin-left: 10px;
  border-radius: 10px;
  color: var(--blue2);
  /* background: var(--blue1); */
  padding: 7px 12px;
  border: none;
  height: 35px;
  background: transparent;
background-blend-mode: soft-light, normal;
/* n-stroke */

border: 1px solid rgba(255, 255, 255, 0.4);
/* n-5-5-10-in */

box-shadow: inset -5px -5px 8px #F9FCFF, inset 5px 5px 7px rgba(174, 174, 192, 0.75);
border-radius: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(./Assets/calIcon.png) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}






@media (max-width: 767.98px) {
  li.paginate_button.previous, li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem; }
  li.paginate_button {
    display: none; }
  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1rem 0 0 !important; }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1rem; } }




    /* Custom date picker */

    .wrapper {
      max-width: 18rem;
      padding: 0 0.5rem;
      margin-left: auto;
      margin-right: auto;
      padding-top: 4rem;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .wrapper label {
      font-size: 0.75rem;
      font-weight: 400;
      display: block;
      margin-bottom: 0.5rem;
      color: #B0BEC5;
      border: 1px solid #ECEFF1;
      padding: 0.5rem 0.75rem;
      border-radius: 0.5rem;
    }
    .wrapper input {
      font-family: 'Roboto', sans-serif;
      display:block;
      border: none;
      border-radius: 0.25rem;
      border: 1px solid transparent;
      line-height: 1.5rem;
      padding: 0;
      font-size: 1rem;
      color: #607D8B;
      width: 100%;
      margin-top: 0.5rem;
    }
    .wrapper input:focus {outline: none;}
    #ui-datepicker-div {
      display: none;
      background-color: #fff;
      box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.1);
      margin-top: 0.25rem;
      border-radius: 0.5rem;
      padding: 0.5rem;
    }
    .wrapper table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    .wrapper .ui-datepicker-calendar thead th {
      padding: 0.25rem 0;
      text-align: center;
      font-size: 0.75rem;
      font-weight: 400;
      color: #78909C;
    }
    .wrapper .ui-datepicker-calendar tbody td {
      width: 2.5rem;
      text-align: center;
      padding: 0;
    }
    .wrapper .ui-datepicker-calendar tbody td a {
      display: block;
      border-radius: 0.25rem;
      line-height: 2rem;
      transition: 0.3s all;
      color: #546E7A;
      font-size: 0.875rem;
      text-decoration: none;
    }
    .wrapper .ui-datepicker-calendar tbody td a:hover {	
      background-color: #E0F2F1;
    }
    .wrapper .ui-datepicker-calendar tbody td a.ui-state-active {
      background-color: #009688;
      color: white;
    }
    .wrapper .ui-datepicker-header a.ui-corner-all {
      cursor: pointer;
      position: absolute;
      top: 0;
      width: 2rem;
      height: 2rem;
      margin: 0.5rem;
      border-radius: 0.25rem;
      transition: 0.3s all;
    }
    .wrapper .ui-datepicker-header a.ui-corner-all:hover {
      background-color: #ECEFF1;
    }
    .wrapper .ui-datepicker-header a.ui-datepicker-prev {	
      left: 0;	
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
      background-repeat: no-repeat;
      background-size: 0.5rem;
      background-position: 50%;
      transform: rotate(180deg);
    }
    .wrapper .ui-datepicker-header a.ui-datepicker-next {
      right: 0;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 50%;
    }
    .wrapper .ui-datepicker-header a>span {
      display: none;
    }
    .wrapper .ui-datepicker-title {
      text-align: center;
      line-height: 2rem;
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      padding-bottom: 0.25rem;
    }
    .wrapper .ui-datepicker-week-col {
      color: #78909C;
      font-weight: 400;
      font-size: 0.75rem;
    }

    table.dataTable.display>tbody>tr.odd>.sorting_1, table.dataTable.order-column.stripe>tbody>tr.odd>.sorting_1 {
      box-shadow: inset 0 0 0 9999px rgb(0 0 0 / 4%) !important;
  }